import CenteredLayout from "@common/components/thirdParty/mantine/layout/CenteredLayout";
import { persistUTMQueryParamsToUrlObject } from "@common/utils/router";
import { Text, Title } from "@mantine/core";
import Link from "next/link";
import { useRouter } from "next/router";
import { ReactElement } from "react";

export default function Error404Page(): ReactElement {
  const router = useRouter();

  return (
    <CenteredLayout wordmark>
      <Title m={0} order={2} size="h3" ta="center">
        Page not found
      </Title>
      <Text fz="sm" m={0} mt="xl" ta="center">
        <Link href={persistUTMQueryParamsToUrlObject(router, "/")}>Back?</Link>
      </Text>
    </CenteredLayout>
  );
}

Error404Page.hideNav = true;
